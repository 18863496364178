import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBannerLeft from "../components/bottom-banner-left";
import SvgFanDarkSo from "../components/svg/fan-dark-svg-so";
import SvgFanLightSo from "../components/svg/fan-light-svg-so";
import SvgFanLightNoPink from "../components/svg/fan-light-no-pink";
import { GatsbyImage } from "gatsby-plugin-image";
import useOnScreen from "../components/use-on-screen";
import gsap from "gsap";
import HubspotForm from "react-hubspot-form";
import PhotoSide from "../components/photo-side";
import TopRightFlateBottom from "../components/top-right-flat-bottom";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
const BackgroundAndEventInformationPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			LFImg: wpMediaItem(title: { eq: "Lightning-Fibre-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "faq-3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ESCImg: wpMediaItem(
				title: { eq: "Enterprise Logo for Dark Backgrounds Carnival" }
			) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			SHImg: wpMediaItem(title: { eq: "Seahaven-FM" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			MAImg: wpMediaItem(title: { eq: "Media Attention" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			CImg: wpMediaItem(title: { eq: "Custard" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			BfImg: wpMediaItem(title: { eq: "Bournefree" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			KImg: wpMediaItem(title: { eq: "Kumon-Updated" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			TBEImg: wpMediaItem(title: { eq: "Eastbourne Community Partner logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			BGImg: wpMediaItem(title: { eq: "Birchwood-Group" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			beaconImg: wpMediaItem(title: { eq: "Beacon Logo Blue" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ditsyImg: wpMediaItem(title: { eq: "Ditzy-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			emImg: wpMediaItem(title: { eq: "Elite-Medical-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			bfImg: wpMediaItem(title: { eq: "Birchwood-Group-Square-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			efImg: wpMediaItem(title: { eq: "EF-Logos_EF" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			mgImg: wpMediaItem(title: { eq: "Eastbourne MG Logo 2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			kumImg: wpMediaItem(title: { eq: "Kumon-logo-new" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			signImg: wpMediaItem(title: { eq: "Signtek-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			rjmImg: wpMediaItem(title: { eq: "rjm-digital-logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			IDImg: wpMediaItem(title: { eq: "Identity_Logo_RedSquare" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			logoImg: wpMediaItem(title: { eq: "Logo-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			mightyImg: wpMediaItem(title: { eq: "High & Mighty Scaffold" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			sdfImg: wpMediaItem(title: { eq: "SDF Business card front" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const LFImg = data.LFImg?.localFile.childImageSharp.gatsbyImageData;
	const img1 = data.img1?.localFile.childImageSharp.gatsbyImageData;
	const logoImg = data.logoImg?.localFile.childImageSharp.gatsbyImageData;
	const ESCImg = data.ESCImg?.localFile.childImageSharp.gatsbyImageData;
	const SHImg = data.SHImg?.localFile.childImageSharp.gatsbyImageData;
	const MAImg = data.MAImg?.localFile.childImageSharp.gatsbyImageData;
	const CImg = data.CImg?.localFile.childImageSharp.gatsbyImageData;
	const BfImg = data.BfImg?.localFile.childImageSharp.gatsbyImageData;
	const KImg = data.KImg?.localFile.childImageSharp.gatsbyImageData;
	const TBEImg = data.TBEImg?.localFile.childImageSharp.gatsbyImageData;
	const BGImg = data.BGImg?.localFile.childImageSharp.gatsbyImageData;
	const beaconImg = data.beaconImg?.localFile.childImageSharp.gatsbyImageData;
	const ditsyImg = data.ditsyImg?.localFile.childImageSharp.gatsbyImageData;
	const emImg = data.emImg?.localFile.childImageSharp.gatsbyImageData;
	const bfImg = data.bfImg?.localFile.childImageSharp.gatsbyImageData;
	const efImg = data.efImg?.localFile.childImageSharp.gatsbyImageData;
	const mgImg = data.mgImg?.localFile.childImageSharp.gatsbyImageData;
	const kumImg = data.kumImg?.localFile.childImageSharp.gatsbyImageData;
	const signImg = data.signImg?.localFile.childImageSharp.gatsbyImageData;
	const rjmImg = data.rjmImg?.localFile.childImageSharp.gatsbyImageData;
	const IDImg = data.IDImg?.localFile.childImageSharp.gatsbyImageData;
	const mightyImg = data.mightyImg?.localFile.childImageSharp.gatsbyImageData;
	const sdfImg = data.sdfImg?.localFile.childImageSharp.gatsbyImageData;

	const refbei = useRef();
	const onScreenbei = useOnScreen(refbei, "100px");

	useEffect(() => {
		if (onScreenbei === true) {
			gsap.to(".purple-section", {
				opacity: 1,
				duration: 1,
				delay: 0.4,
				transform: "scale(1)",
			});
			gsap.to(".yellow-section", {
				opacity: 1,
				duration: 1,
				delay: 0.7,
				transform: "scale(1)",
			});
		}
	}, [onScreenbei]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Background & Event Information",
				item: {
					url: `${siteUrl}/background-and-event-information`,
					id: `${siteUrl}/background-and-event-information`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Background & Event Information | Eastbourne Carnival"
				description="Eastbourne Carnival began life in 1895 as part of the Battle of the Flowers Celebration. In more recent times, it was brought back by the then MP for Eastbourne, Stephen Lloyd in 2011 after an absence of 15+ years."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/background-and-event-information`,
					title: "Background & Event Information | Eastbourne Carnival",
					description:
						"Eastbourne Carnival began life in 1895 as part of the Battle of the Flowers Celebration. In more recent times, it was brought back by the then MP for Eastbourne, Stephen Lloyd in 2011 after an absence of 15+ years.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<section className="pt-5 pt-lg-8  pb-5  position-relative">
				<Container>
					<Row className="justify-content-center pb-7">
						<Col>
							<h1 className="  display-4 pb-4 carnival-normal text-secondary">
								Background & Event Information
							</h1>

							<p>
								Eastbourne Carnival began life in 1895 as part of the Battle of
								the Flowers Celebration.
							</p>
							<p>
								In more recent times, it was brought back by the then MP for
								Eastbourne, Stephen Lloyd in 2011 after an absence of 15+ years.
								In 2021, Stephen Lloyd handed the position of Committee Chair to
								Mina O’Brien, who, with the Committee has now delivered
								successful Carnivals in 2022, and 2023.
							</p>
							<p>
								The Carnival is now an annual event taking place on the Saturday
								of the late May Bank Holiday weekend.  There are three main
								components of the event held on the day: 
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				{" "}
				<Container className="px-0 mx-0" fluid>
					{" "}
					<Row ref={refbei} className="h-100">
						<Col style={{ minHeight: "100%" }} className="px-0  mx-0" lg={6}>
							<div
								style={{
									minHeight: "100%",
								}}
								className="px-xl-10 px-5 px-lg-7  bg-secondary text-center position-relative pb-md-10 pb-6 pt-md-8 pt-6 text-primary"
							>
								<div
									className="purple-section"
									style={{ opacity: 0, transform: "scale(0.95)" }}
								>
									<p className="text-start pb-4">
										We are hoping to attract a good mix of entrants, including
										community groups, businesses, charities and schools.
										Additionally, we enable individuals and families to get
										involved in our ‘Everyone’s Carnival’ group. We work with
										local artists and performers who deliver Carnival workshops
										to help create costumes and deliver dance workshops. 
									</p>
									<span className="d-flex align-items-center mb-4">
										<div className="me-3" style={{ minWidth: "60px" }}>
											<SvgFanLightSo
												style={{ width: "60px", height: "auto" }}
											/>
										</div>
										<p
											style={{ fontSize: "100%" }}
											className="d-inline-block text-start pb-0 mb-0"
										>
											At 11:30am, Carnival is officially launched by the Mayor
											of Eastbourne in Eastbourne Town Centre (outside the
											Enterprise Shopping Centre) with a small procession of
											costumes and drummers through town centre, ending at the
											top of Victoria Place (Terminus Road). 
										</p>
									</span>
									<span className="d-flex align-items-center mb-4">
										<div className="me-3" style={{ minWidth: "60px" }}>
											<SvgFanLightSo
												style={{ width: "60px", height: "auto" }}
											/>
										</div>
										<p
											style={{ fontSize: "100%" }}
											className="d-inline-block text-start pb-0 mb-0"
										>
											The Main Parade starts at 2pm from Fisherman’s Green, and
											ends approximately 4:30pm at the Wish Tower Slopes 
										</p>
									</span>
									<span className="d-flex align-items-center mb-4">
										<div className="me-3" style={{ minWidth: "60px" }}>
											<SvgFanLightSo
												style={{ width: "60px", height: "auto" }}
											/>
										</div>
										<p
											style={{ fontSize: "100%" }}
											className="d-inline-block text-start pb-0 mb-0"
										>
											A Street Party is held after the main procession at
											Victoria Place until 8pm. 
										</p>
									</span>
								</div>
							</div>
						</Col>
						<Col className="px-0 mx-0" lg={6}>
							<div className="px-xl-10 px-lg-7  px-5 bg-primary text-center position-relative pb-md-10 pb-6 pt-md-8 pt-6 text-secondary">
								<div
									className="yellow-section"
									style={{ opacity: 0, transform: "scale(0.95)" }}
								>
									<h3 className=" pb-4 shortstack">
										The theme for Eastbourne Carnival 2024 is ‘Into The Wild.’
									</h3>
									<p className="text-start ">
										In 2022 and 2023, we worked with Sunshine International
										Arts, who are a longstanding Notting Hill Carnival band – to
										create our big ‘King and Queen’ Costumes, and supply smaller
										costumes featured at the front of the Parade.
									</p>
									<p className="text-start ">
										In 2023, we engaged the Metronomes Steel Orchestra, and they
										came down from Notting Hill to perform at the beginning of
										the Parade.
									</p>
									<p className="text-start fw-bold text-darkPurple">
										We could not achieve this without the support and
										sponsorship from the local business community.
									</p>
									<p className="text-start">
										We anticipate that 2024 will be even bigger – and we are
										expecting more entrants, and a bigger crowd. 
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<Row>
						<Col className="text-center">
							<Button
								variant="darkPurple"
								className="fs-5 mx-5 mx-md-0 mt-5 py-3 mx-auto px-5   text-white jost-normal bottom-0"
								as={Link}
								to="/sponsorship-opportunities"
							>
								GET IN TOUCH
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7 mb-md-6">
				<Container>
					<PhotoSide
						image={img1}
						imageAlt={data.img1?.altText}
						order="last"
						orderSmall="last"
						heading={
							<div>
								<h2 className="  fs-2 mb-3 carnival-bold text-secondary">
									Marketing the Carnival
								</h2>
							</div>
						}
						text={
							<span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block text-start pb-0 mb-0"
									>
										Working with Bourne Free and Custard Print for regular
										features from February – May
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block text-start pb-0 mb-0"
									>
										Working with Seahaven FM to run Ads in the build up to the
										event 
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block text-start pb-0 mb-0"
									>
										Working with Media Attention who place Digital Billboards
										and Posters around Eastbourne with Adverts for Carnival, and
										who also put a window display together a month before the
										event
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block text-start pb-0 mb-0"
									>
										Social Media, provided by Ditzy Media, with organic and paid
										ads to reach the community throughout the period February –
										May, but with increased posting March - May to advertise
										sponsors.
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block text-start pb-0 mb-0"
									>
										In 2023, Eastbourne Carnival was picked up internationally
										by the wider Carnival community and was featured in ‘Soca
										News’ –{" "}
										<a
											rel="noreferrer"
											target="_blank"
											href="https://socanews.com/news/eastbourne-carnival-parades-its-bands-bees-and-bonfires/"
										>
											See Here
										</a>
									</p>
								</span>
								<span className="d-flex align-items-center mb-4">
									<div className="me-3" style={{ minWidth: "60px" }}>
										<SvgFanLightSo style={{ width: "60px", height: "auto" }} />
									</div>
									<p
										style={{ fontSize: "100%" }}
										className="d-inline-block text-start pb-0 mb-0"
									>
										We also get a lot of post event coverage and in 2022 were
										featured on both BBC South East Today and Meridian News
									</p>
								</span>
							</span>
						}
					/>
				</Container>
			</section>
			<section>
				<TopRightFlateBottom
					content={
						<Row className="pb-5 pb-md-0">
							<Col className=" pt-5 pt-md-10 mt-md-4 text-center">
								<p className="display-5 text-white ">
									Our social media reach in{" "}
									<span className="jost-bold">2023</span> was over{" "}
									<span className="jost-bold">150,000</span>
								</p>
								<p className="text-white  ">
									Our YouTube Channel has videos of the 2022 and 2023 Carnivals:
								</p>
								<div className="d-flex pt-4 justify-content-center align-items-center">
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.facebook.com/eastbournecarnival"
									>
										<FaFacebookF className="text-white display-3 link-white" />
									</a>

									<a
										rel="noreferrer"
										target="_blank"
										href="https://twitter.com/EBCarnival"
									>
										<FaTwitter className="text-white display-3 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.instagram.com/eastbournecarnival/"
									>
										<FaInstagram className="text-white display-3 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.youtube.com/@eastbournecarnival990"
									>
										<FaYoutube className="text-white display-3 ms-5 link-white" />
									</a>
								</div>
								{/* <h2 className=" display-2 d-none d-md-block text-primary carnival-bold">
                  MERCH OUT NOW!
                </h2>
                <h2 className=" display-1 d-md-none text-primary carnival-bold">
                  MERCH OUT NOW!
                </h2>
                <p className="display-4 text-white d-none d-md-block carnival-bold">
                  <span className="">GET </span>
                  <span className="text-lightBlue">CARNIVAL</span>
                  <span className="text-mainRed"> READY</span>
                </p>
                <p className="display-3 text-white d-md-none carnival-bold">
                  <span className="">GET </span>
                  <span className="text-lightBlue">CARNIVAL</span>
                  <span className="text-mainRed"> READY</span>
                </p>

                <p className="text-primary pb-4">
                  Grab yourself a Carnival souvenir T-shirt,{" "}
                  <br className="d-none d-lg-block" />
                  kids size also available!
                </p>
                <Button
                  variant="lightBlue"
                  className="fs-5 py-3 mt-3 mb-0  w-100 w-md-auto mx-auto px-5 text-white jost-normal"
                  rel="noreferrer"
                  target="_blank"
                  href="https://ashprint.co.uk/shop/21-eastbourne-carnival-clothing"
                >
                  ONLINE MERCH STORE
                </Button> */}
							</Col>
						</Row>
					}
					mainBg="bg-secondary"
					secondBg="bg-primary"
					position="top--20"
				/>
			</section>
			<BottomBannerLeft
				mainBg="bg-lightBlue"
				position="top--40"
				content={
					<Row>
						<Col className="py-5 py-md-0">
							<h2 className="text-white text-center mt-md-5  pt-md-10 fs-1 shortstack">
								GET IN TOUCH TO REGISTER YOUR INTEREST
							</h2>
							<div className="d-flex pt-5 justify-content-center align-items-center">
								<Button
									variant="outline-white"
									className="fs-5 py-3  px-4 jost-normal"
									as={Link}
									to="/sponsorship-opportunities"
								>
									CONTACT US
								</Button>
							</div>
						</Col>
					</Row>
				}
			/>
		</Layout>
	);
};

export default BackgroundAndEventInformationPage;
