import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const PhotoSide = ({
	image,
	imageAlt,
	heading,
	text,
	order,
	orderSmall,
	animId,
}) => {
	return (
		<Row className="align-items-center py-4 py-md-0">
			<Col
				className={`  my-4 my-lg-0 ${order ? "ps-xl-7" : "pe-xl-7"}`}
				xs={{ span: 12, order: orderSmall }}
				lg={{ span: 6, order: order }}
			>
				<div className="side-image" style={{ overflow: "hidden" }}>
					{animId && (
						<GatsbyImage
							style={{ opacity: 0, transform: "scale(0.95)" }}
							class={`w-100 ${animId}`}
							image={image}
							alt={imageAlt}
						/>
					)}
					{!animId && (
						<GatsbyImage class={`w-100 `} image={image} alt={imageAlt} />
					)}
				</div>
			</Col>
			<Col className="pe-lg-5" lg={6}>
				{heading}
				{text}
			</Col>
		</Row>
	);
};

export default PhotoSide;
